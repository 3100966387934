<template>
    <div style="height: 1000px;padding-left: 20px;">
        <div style="float: left;">
            <el-upload  class="upload-demo"  action="" drag multiple :http-request="upload_face" :show-file-list="false">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">身份证正面<em>点击上传</em></div>
            </el-upload>
            <div style="width: 300px;height: 300px;">
                <img :src="face" style="width: 100%;height: 100%;">
            </div>
        </div>
        <div style="float: left;">
            <el-upload  class="upload-demo"  action="" drag multiple :http-request="upload_back" :show-file-list="false">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">身份证背面<em>点击上传</em></div>
            </el-upload>
            <div style="width: 300px;height: 300px;">
                <img :src="back" style="width: 100%;height: 100%;">
            </div>
        </div>
        <div style="float: left;">
            <el-upload  class="upload-demo"  action="" drag multiple :http-request="upload_license" :show-file-list="false">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">开户许可证<em>点击上传</em></div>
            </el-upload>
            <div style="width: 300px;height: 300px;">
                <img :src="license" style="width: 100%;height: 100%;">
            </div>
        </div>
        <div style="float: left;width: 50%;margin-top:30px;height: 50px;">
            <template>
                <el-select v-model="bankCode" filterable placeholder="请选择">
                    <el-option
                            v-for="item in bankList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </template>
        </div>
        <div style="width: 320px;">
            <el-input type="number" v-model="cardNo" placeholder="请输入卡号"></el-input>
        </div>
        <div style="width: 320px;margin-top: 15px;">
            <el-input v-model="user.legalPersonPhone" placeholder="法人手机号"></el-input>
        </div>
        <div style="margin-top: 20px;">
            <el-button type="primary" @click="subData">提交</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "corp_auth",
        data() {
            return {
                cardNo: '',
                bankList: [],
                bankCode: '',
                phone:"",
                face:"",
                back:'',
                license:'',
            }
        },
        computed: {
            user() {
                return this.$store.state.user
            }
        },
        mounted() {
            this.getBankList();
        },
        methods:{
            upload_face:function (params) {
                var that = this;
                let file = params.file;
                this.utils.upload(file,function(url){
	                if (!url){
		                return false;
	                }
                    console.log(url);
                    that.face = url;

                });

            },
            upload_back:function (params) {
                var that = this;
                let file = params.file;
                this.utils.upload(file,function(url){
	                if (!url){
		                return false;
	                }
                    that.back = url;

                });

            },
            upload_license:function (params) {
                var that = this;
                let file = params.file;
                this.utils.upload(file,function(url){
	                if (!url){
		                return false;
	                }
                    that.license = url;

                });

            },
            getBankList(){
                var that = this;
                var params = {};
                that.newApi.getBankList(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.bankList = res.data;
                    }
                })
            },
            subData(){
                var that = this;
                var params = {};
                params.idCardFace = that.face;
                params.idCardBack = that.back;
                params.idCarlicense = that.license;
                params.bankCode = that.bankCode;
                params.cardNo = that.cardNo;
                params.phone = that.user.legalPersonPhone;
                console.log(params);
                that.newApi.corpMemberCreate(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.utils.sus(res.data);
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>
